<template>
  <v-card class="fill-height border pa-5">
    <FullCalendar 
      :options="eventss"/>
  </v-card>
  <!-- <section>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn class="text-capitalize poppins mr-4" outlined small @click="setToday">
              Today
            </v-btn>
            <v-btn icon fab small @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
             <v-btn icon fab small @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar" class="poppins f16 mx-1"> {{ $refs.calendar.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  small
                  color="grey darken-2"
                  class="text-capitalize poppins"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="text-capitalize">{{type}}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(label, i) in typeToLabel" :key="i" @click="type=label">
                  <v-list-item-title class="text-capitalize">{{label}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar 
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          />
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-y
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark  height="50">
                <v-toolbar-title class="poppins f16 fw600" v-html="selectedEvent.name"/>
                <v-spacer></v-spacer>
                <v-btn icon small>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.details"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  small
                  text
                  color="secondary-1"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </section> -->
</template>

<script>
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    FullCalendar,
  },
  data: () => ({
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'month',
      week: 'week',
      day: 'day',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'gre-darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
  }),

  mounted() {
    this.getCalendarEventsAction()
  },

  computed: {
    ...mapState('usr', {
      eventss(state){
        var vm = this;
        let _events = state.calendar
        let options = {
          plugins: [ dayGridPlugin, listPlugin, timeGridPlugin ],
          initialView: 'dayGridMonth',
          events: _events,
          // headerToolbar: {
          //   left: 'prev,next today',
          //   center: 'title',
          //   right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
          // },
          contentHeight: 'auto',
          eventClick: function(info) {
            var eventObj = info.event;
            vm.$router.push({ name: 'User Course Details', params: { id: eventObj.extendedProps.uuid}})
          },
        }
        return options
      }
    }),

  },

  methods: {
    ...mapActions('usr', ['getCalendarEventsAction']),

    // setToday () {
    //   this.focus = ''
    // },
    // prev () {
    //   this.$refs.calendar.prev()
    // },
    // next () {
    //   this.$refs.calendar.next()
    // },
    // viewDay ({ date }) {
    //   this.focus = date
    //   this.type = 'day'
    // },
    // getEventColor (event) {
    //   return event.color
    // },
    // showEvent ({ nativeEvent, event }) {
    //   const open = () => {
    //     this.selectedEvent = event
    //     this.selectedElement = nativeEvent.target
    //     requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
    //   }

    //   if (this.selectedOpen) {
    //     this.selectedOpen = false
    //     requestAnimationFrame(() => requestAnimationFrame(() => open()))
    //   } else {
    //     open()
    //   }

    //   nativeEvent.stopPropagation()
    // },
    // updateRange ({ start, end }) {
    //   const events = []

    //   const min = new Date(`${start.date}T00:00:00`)
    //   const max = new Date(`${end.date}T23:59:59`)
    //   const days = (max.getTime() - min.getTime()) / 86400000
    //   const eventCount = this.rnd(days, days + 20)

    //   for (let i = 0; i < eventCount; i++) {
    //     const allDay = this.rnd(0, 3) === 0
    //     const firstTimestamp = this.rnd(min.getTime(), max.getTime())
    //     const first = new Date(firstTimestamp - (firstTimestamp % 900000))
    //     const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
    //     const second = new Date(first.getTime() + secondTimestamp)

    //     events.push({
    //       name: this.names[this.rnd(0, this.names.length - 1)],
    //       start: first,
    //       end: second,
    //       color: this.colors[this.rnd(0, this.colors.length - 1)],
    //       timed: !allDay,
    //     })
    //   }

    //   this.events = events
    // },
    // rnd (a, b) {
    //   return Math.floor((b - a + 1) * Math.random()) + a
    // },
  }
}
</script>

<style>
  .fc-toolbar-title {
    font-family: 'Poppins';
  }
  span.fc-icon.fc-icon-chevron-left::before {
    vertical-align: initial !important;
  }
  span.fc-icon.fc-icon-chevron-right::before {
    vertical-align: initial !important;
  }
</style>
